.p_card{
  color: rgba(0, 0, 0, 0.6) !important ;

}

.p_card {
  cursor: pointer ;
}

.p_card:hover{
  background-color: purple !important;
  color: white !important ;

}



/* .shopbyprice a {
  background-color: #3514afee !important;
  color: aliceblue;
}

.shopbyprice a:hover {
  background-color: #270897ee !important;
  color: aliceblue;
} */

